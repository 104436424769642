import { Button } from "@mui/material";
import { useRef, useState } from "react";
import styled from "styled-components";
import { CustomUploadIcon } from "../Icons";
import InputBase from "./Base";

const File = styled.input`
  display: none;
  position: absolute;
  right: 0;
  top: -1000%;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const InputFile = ({ onChange, accept, placeholder = "파일을 업로드 해주세요." }) => {
  const fileRef = useRef(null);
  const [file, setFile] = useState();
  const handleFileChange = e => {
    const newFile = e.target.files[0];
    setFile(newFile);
    onChange && onChange(newFile);
  };

  return (
    <>
      <File
        ref={fileRef}
        type="file"
        accept={accept ?? ".pdf,.jpg,.jpeg,.png"}
        onChange={handleFileChange}
      />
      <InputBase
        value={file?.name?.length > 10 ? `${file.name.slice(0, 27)}...` : file?.name}
        placeholder={placeholder}
        readOnly
        sx={{ width: "100%" }}
      />
      <Button
        startIcon={<CustomUploadIcon />}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          fileRef.current.click();
        }}
        sx={{ width: "95px" }}
      >
        파일 업로드
      </Button>
    </>
  );
};

export default InputFile;
