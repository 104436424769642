import { dateToString } from "@babitalk/utils";
import koLocale from "date-fns/locale/ko";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { DateRangePicker as ReactDateRange } from "react-date-range";
import styled from "styled-components";
import { CustomCalendarIcon } from "../Icons";
import InputBase from "../Input/Base";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  position: relative;
`;

const Dash = styled.div`
  width: 8px;
  height: 1px;
  background: #dadadf;
`;

const BackDim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const ReactDateRangeStyle = styled(ReactDateRange)`
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid #ebebeb;
  margin-top: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  &.fix {
    position: relative;
    top: 0;
  }
`;

// props: startDate, endDate, maxRange, minDate, position
const DateRangePicker = ({
  error,
  focusProps,
  inputStyle,
  onChange,
  startDateDisabled = false,
  endDateDisabled = false,
  disabled = false,
  ...props
}) => {
  const [datePickerFocus, setDatePickerFocus] = useState(false);

  useEffect(() => {
    if (focusProps?.isFocus) {
      setDatePickerFocus(true);
      focusProps.setIsFocus(false);
    }
  }, [focusProps]);

  const handleSelect = date => {
    const { startDate, endDate } = date.section;

    if (props.maxRange) {
      if (dayjs().isBefore(endDate, "day")) date.section.endDate = new Date();

      const maxRangeDate = dayjs(endDate).subtract(props.maxRange - 1, "day");
      if (dayjs(startDate).isBefore(maxRangeDate, "day"))
        date.section.startDate = new Date(maxRangeDate);
    } else {
      if (props.minDate && dayjs(startDate).isBefore(props.minDate, "day"))
        date.section.startDate = new Date(props.minDate);

      if (props.maxDate && dayjs(endDate).isAfter(props.maxDate, "day"))
        date.section.endDate = new Date(props.maxDate);
    }

    if (startDateDisabled || disabled) {
      date.section.startDate = new Date(props.startDate);
    }

    if (endDateDisabled || disabled) {
      date.section.endDate = new Date(props.endDate);
    }

    onChange(date.section);
  };

  return (
    <Wrapper ref={props?.inputRef}>
      <InputBase
        type="text"
        className={`form-control date_picker ${error ? "error" : ""}`}
        value={dateToString(props.startDate ? new Date(props.startDate) : new Date())}
        onClick={() => setDatePickerFocus(true)}
        sx={{ maxWidth: "162px", ...inputStyle }}
        startAdornment={<CustomCalendarIcon fontSize="xsmall" color="grey500" />}
        readOnly
        disabled={startDateDisabled || disabled}
      />
      <Dash />
      <InputBase
        type="text"
        className={`form-control date_picker ${error ? "error" : ""}`}
        value={dateToString(props.endDate ? new Date(props.endDate) : new Date())}
        onClick={() => setDatePickerFocus(true)}
        sx={{ maxWidth: "162px", ...inputStyle }}
        startAdornment={<CustomCalendarIcon fontSize="xsmall" color="grey500" />}
        readOnly
        disabled={endDateDisabled || disabled}
      />
      {datePickerFocus && (
        <>
          <BackDim onClick={() => setDatePickerFocus(false)} />
          <ReactDateRangeStyle
            className={`wrap_date_picker ${props.position === "fixed" ? "fix" : ""}`}
            locale={koLocale}
            dateDisplayFormat="yyyy-MM-dd"
            ranges={[
              {
                startDate: new Date(props.startDate),
                endDate: new Date(props.endDate),
                key: "section",
              },
            ]}
            minDate={props?.minDate ? new Date(props?.minDate) : undefined}
            maxDate={props?.maxDate ? new Date(props?.maxDate) : undefined}
            onChange={handleSelect}
          />
        </>
      )}
    </Wrapper>
  );
};

export default DateRangePicker;
