import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell, { tableCellClasses } from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import Pagination from "../Pagination";

const TableContainer = styled(MuiTableContainer)(({ containertype }) => {
  let paddingSize;
  switch (containertype) {
    case "page":
      paddingSize = 60;
      break;
    default:
      paddingSize = 0;
      break;
  }

  return {
    display: "block",
    whiteSpace: "nowrap",
    borderRadius: "6px",
    ...(paddingSize && {
      padding: `0 ${paddingSize}px 20px`,
      margin: `0px -${paddingSize}px`,
      width: `calc(100% + ${paddingSize * 2}px)`,
    }),
  };
});

const StyledTable = styled(MuiTable)(({ theme }) => ({
  borderRadius: "6px",
  border: `1px solid ${theme.palette.grey200.main}`,
  borderCollapse: "inherit",
}));

const Placeholder = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  margin: "32px 0px",
}));

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  "& th:first-of-type": {
    borderRadius: "6px 0px 0px 0px",
  },
  "& th:last-child": {
    borderRadius: "0px 6px 0px 0px",
  },
  "&:last-child td:last-child": {
    borderRadius: "0px 0px 6px 0px",
  },
  "&:first-of-type th": {
    borderTop: "none",
  },
  "&:hover": {
    background: theme.palette.grey100.main,
  },
}));

const TableCell = styled(MuiTableCell)(({ theme, customHeight }) => ({
  padding: "8px 12px",
  color: theme.palette.grey700.main,
  fontSize: 13,
  borderTop: `1px solid ${theme.palette.grey200.main}`,
  borderBottom: "none",
  height: `${customHeight || 40}px !important`,
  lineHeight: `${customHeight || 40}px !important`,

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary100.main,
    fontWeight: 600,
    cursor: "default",
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 400,
    lineHeight: "150%",
    whiteSpace: "normal",
  },
  ["&:not(:last-child)"]: {
    borderRight: `1px solid ${theme.palette.grey200.main}`,
  },
}));

const TableItem = React.memo(({ item, template, index }) => {
  return (template.hasRowSpan && item.rowSpan) || !template.hasRowSpan ? (
    <TableCell
      className={`${template.className} ${template.tdClassName}`}
      style={template.style}
      onClick={template.onClick}
      rowSpan={template.hasRowSpan ? item.rowSpan : 1}
      sx={template.sx}
      customHeight={template.customHeight}
    >
      {template.render(item, index)}
    </TableCell>
  ) : (
    <></>
  );
});

const Table = ({
  items,
  templates,
  keyName = undefined,
  onClickRow = undefined,
  onMouseEnterRow = undefined,
  onMouseLeaveRow = undefined,
  trStyle = undefined,
  paginate = undefined,
  setPage = undefined,
  containerType = "page",
  textAlign = "left",
  placeholder = <></>,
  sx = {},
  headerHeight = 40,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "unset",
        gap: 16,
      }}
    >
      <TableContainer sx={sx} containertype={containerType}>
        <StyledTable sx={{ minWidth: 700 }} aria-label="customized table" {...props}>
          <TableHead>
            <TableRow>
              {templates.map((template, index) => {
                return (
                  <TableCell
                    key={`th_${index}`}
                    className={template.className}
                    style={{ textAlign: "left", width: template.style?.width, ...template.thStyle }}
                    customHeight={headerHeight} // headerHeight를 TableCell에 전달하여 높이 조정
                  >
                    {template.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!items?.length && (
              <TableRow>
                <TableCell colSpan={templates.length} sx={{ color: "#A6A7AA" }}>
                  <Placeholder>{placeholder || "데이터가 없습니다."}</Placeholder>
                </TableCell>
              </TableRow>
            )}
            {items?.map((item, index) => {
              return (
                <TableRow
                  key={`tr_${item[keyName] ?? index}`}
                  onClick={onClickRow && (e => onClickRow(item, e))}
                  style={{
                    ...(onClickRow ? { cursor: "pointer" } : {}),
                    ...(trStyle ? trStyle(item) : {}),
                  }}
                >
                  {templates.map(template => {
                    return (
                      <TableItem
                        key={`item_${item[keyName] ?? index}_${template.code}`}
                        template={template}
                        item={item}
                        index={index}
                        keyName={keyName}
                      />
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {paginate && (
        <Pagination
          key={paginate?.page}
          count={
            Number(paginate?.total_page) ||
            Math.ceil(paginate?.total / Number(paginate?.page_limit)) ||
            1
          }
          page={Number(paginate?.page)}
          onChange={(e, p) => {
            setPage({ page: p });
          }}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  items: PropTypes.array.isRequired,
  templates: PropTypes.array.isRequired,
  keyName: PropTypes.string.isRequired,
  onClickRow: PropTypes.func,
  onMouseEnterRow: PropTypes.func,
  onMouseLeaveRow: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerHeight: PropTypes.number,
  paginate: PropTypes.object,
};

Table.defaultProps = {
  keyName: "id",
};

export default React.memo(Table);
