import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { typography } from "../../theme";
import { colors } from "../../theme/palette";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const Icon = styled.div`
  color: ${colors.grey[300]};
  font-size: 24px;
  line-height: 1 !important;
`;

const Title = styled.div`
  color: ${colors.grey[600]};
  ${typography.subtitle2};
`;

const Text = styled.div`
  color: ${colors.grey[500]};
  ${typography.body2}
`;

const Empty = ({ icon, title, text }) => {
  return (
    <Wrapper>
      {icon && <Icon>{icon}</Icon>}
      <TextWrapper>
        {title && <Title>{title}</Title>}
        {text && <Text>{text}</Text>}
      </TextWrapper>
    </Wrapper>
  );
};

Empty.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Empty;
